import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import bnrImg from "../../images/banner/bnr3.jpg";

class Error404 extends Component{

	render(){
		return (
			<>
				<div className="page-banner ovbl-dark" style={{backgroundImage: "url("+bnrImg+")"}}>
					<div className="container">
						<div className="page-banner-entry text-center">
							<h1><span>Page not found</span></h1>
							<nav aria-label="breadcrumb" className="breadcrumb-row">
								<ul className="breadcrumb">
									<li className="breadcrumb-item"><Link to="/"><i className="las la-home"></i>Home</Link></li>
									<li className="breadcrumb-item active" aria-current="page">404</li>
								</ul>
							</nav>
						</div>
					</div>
				</div>
				<div className="error-404">
					<div className="inner-content container">
						<h2 className="error-title">4<span></span>4</h2>
						<h3 className="error-text">The Page you were looking for, couldn't be found.</h3>
						<p>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
						<div className="clearfix">
							<Link to="/" className="btn btn-primary m-r5">Back</Link>
							<Link to="/" className="btn btn-primary">Back To Home</Link>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default Error404;
